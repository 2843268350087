export const CURRENT_TOOLBAR_VIEW = {
  SHOW_DEFAULT_DISPLAY: 'SHOW_DEFAULT_DISPLAY',
  SHOW_GENERATE_DISPLAY: 'SHOW_GENERATE_DISPLAY',
  SHOW_TEMPLATES_DISPLAY: 'SHOW_TEMPLATES_DISPLAY',
  SHOW_EDIT_MASK_DISPLAY: 'SHOW_EDIT_MASK_DISPLAY',
  SHOW_ADD_TEXT_DISPLAY: 'SHOW_ADD_TEXT_DISPLAY',
  SHOW_LAYERS_DISPLAY: 'SHOW_LAYERS_DISPLAY',

  SHOW_CURSOR_SELECT_DISPLAY: 'SHOW_CURSOR_SELECT_DISPLAY',
  SHOW_ANIMATE_DISPLAY: 'SHOW_ANIMATE_DISPLAY',
  SHOW_OBJECT_SELECT_DISPLAY: 'SHOW_OBJECT_SELECT_DISPLAY',
  SHOW_ACTIONS_DISPLAY: 'SHOW_ACTIONS_DISPLAY',
  SHOW_SELECT_DISPLAY: 'SHOW_SELECT_DISPLAY',

  SHOW_ADD_SHAPE_DISPLAY: 'SHOW_ADD_SHAPE_DISPLAY',
  SHOW_UPLOAD_DISPLAY: 'SHOW_UPLOAD_DISPLAY',
  SHOW_AUDIO_DISPLAY: 'SHOW_AUDIO_DISPLAY',

  SHOW_SET_DEFAULTS_DISPLAY: 'SHOW_SET_DEFAULTS_DISPLAY',

  SHOW_GENERATE_VIDEO_DISPLAY: 'SHOW_GENERATE_VIDEO_DISPLAY',

}

export const TOOLBAR_ACTION_VIEW = {
  SHOW_DEFAULT_DISPLAY: 'SHOW_DEFAULT_DISPLAY',


  SHOW_ERASER_DISPLAY: 'SHOW_ERASER_DISPLAY',
  SHOW_PENCIL_DISPLAY: 'SHOW_PENCIL_DISPLAY',

  SHOW_SELECT_LAYER_DISPLAY: 'SHOW_SELECT_LAYER_DISPLAY',
  SHOW_SELECT_SHAPE_DISPLAY: 'SHOW_SELECT_SHAPE_DISPLAY',
  
  SHOW_SELECT_OBJECT_DISPLAY: 'SHOW_SELECT_OBJECT_DISPLAY',

  SHOW_MUSIC_GENERATE_DISPLAY: 'SHOW_MUSIC_GENERATE_DISPLAY',
  SHOW_SPEECH_GENERATE_DISPLAY: 'SHOW_SPEECH_GENERATE_DISPLAY',
  SHOW_SOUND_GENERATE_DISPLAY: 'SHOW_SOUND_GENERATE_DISPLAY',

  SHOW_PREVIEW_MUSIC_DISPLAY: 'SHOW_PREVIEW_MUSIC_DISPLAY',
  SHOW_PREVIEW_SPEECH_DISPLAY: 'SHOW_PREVIEW_SPEECH_DISPLAY',
  SHOW_PREVIEW_SOUND_DISPLAY: 'SHOW_PREVIEW_SOUND_DISPLAY',

  SHOW_LIBRARY_DISPLAY: 'SHOW_LIBRARY_DISPLAY',
  SHOW_SMART_SELECT_DISPLAY: 'SHOW_SMART_SELECT_DISPLAY',

  SHOW_SUBTITLES_DISPLAY: 'SHOW_SUBTITLES_DISPLAY',
}

export const FRAME_TOOLBAR_VIEW = {
  DEFAULT: 'DEFAULT',
  AUDIO: 'AUDIO',
}

export const CURRENT_EDITOR_VIEW = {
  'VIEW': 'VIEW',
  'EDIT': 'EDIT',  
}

export const IMAGE_GENERAITON_MODEL_TYPES = [

  {
    name: 'Dall-E 3',
    key: 'DALLE3'
  },

  {
    name: 'Flux-1 Pro',
    key: 'FLUX1PRO'
  },
  {
    name: 'Flux-1.1 Pro',
    key: 'FLUX1.1PRO'
  },
  {
    name: 'Flux 1.1 Ultra',
    key: 'FLUX1.1ULTRA'
  },

  {
    name: 'Flux-1 Dev',
    key: 'FLUX1DEV'
  },

  {
    name: 'Recraft V3',
    key: 'RECRAFTV3'
  },

  {
    name: 'Stable Diffusion V3.5',
    key: 'SDV3.5'
  }



]

export const VIDEO_GENERATION_MODEL_TYPES = [
  {
    name: 'Luma AI',
    key: 'LUMA'
  },
  {
    name: 'SD Video',
    key: 'SDVIDEO'
  },
  {
    name: 'Runway Gen-3',
    key: 'RUNWAYML'
  },
  {
    name: 'Hailuo Minimax',
    key: 'HAILUO'
  },
  {
    name: 'Haiper 2.0',
    key: 'HAIPER2.0'
  }

]


export const IMAGE_EDIT_MODEL_TYPES = [
  // {
  //   name: 'Stable Diffusion XL',
  //   key: 'SDXL',
  // },
  {
    name: 'Dall-E 2',
    key: 'DALLE2',
  },
]


export const CANVAS_ACTION = {
  MOVE: 'MOVE',
  EDIT: 'EDIT',
  RESIZE: 'RESIZE',
  DEFAULT: 'DEFAULT',
}


export const SPEAKER_TYPES = [
  'alloy',
  'echo',
  'fable',
  'onyx',
  'nova',
  'shimmer'
];


export const SPEECH_SELECT_TYPES = {
  SPEECH_LAYER: 'SPEECH_LAYER',
  SPEECH_PER_SCENE: 'SPEECH_PER_SCENE',
};


export const ASSISTANT_MODEL_TYPES = [
  'GPT4O',
  'GEMINI1.5PRO'
];



export const RECRAFT_IMAGE_STYLES = [
  "realistic_image", 
  "digital_illustration", 
  "vector_illustration", 
  "realistic_image/b_and_w", 
  "realistic_image/hard_flash", 
  "realistic_image/hdr", 
  "realistic_image/natural_light", 
  "realistic_image/studio_portrait", 
  "realistic_image/enterprise", 
  "realistic_image/motion_blur", 
  "digital_illustration/pixel_art", 
  "digital_illustration/hand_drawn", 
  "digital_illustration/grain", 
  "digital_illustration/infantile_sketch", 
  "digital_illustration/2d_art_poster", 
  "digital_illustration/handmade_3d", 
  "digital_illustration/hand_drawn_outline", 
  "digital_illustration/engraving_color", 
  "digital_illustration/2d_art_poster_2", 
  "vector_illustration/engraving", 
  "vector_illustration/line_art", 
  "vector_illustration/line_circuit", 
  "vector_illustration/linocut"
];

