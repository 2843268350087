import React, { useEffect, useRef, useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const PROCESSOR_API_URL = process.env['REACT_APP_PROCESSOR_API'];

export default function VideoUnderlay(props) {
  const {
    aiVideoLayer,
    currentLayerSeek = 0,
    removeVideoLayer,
    canvasDimensions,
  } = props;

  const videoRef = useRef(null);
  const [videoSrc, setVideoSrc] = useState('');
  const [isVideoReady, setIsVideoReady] = useState(false);

  // Preload video when aiVideoLayer changes
  useEffect(() => {
    if (aiVideoLayer) {
      const newVideoSrc = `${PROCESSOR_API_URL}${aiVideoLayer}`;

      // If the video source is different, preload it
      if (videoSrc !== newVideoSrc) {
        setIsVideoReady(false);

        // Preload the new video
        const video = document.createElement('video');
        video.src = newVideoSrc;
        video.preload = 'auto';

        const handleCanPlayThrough = () => {
          setIsVideoReady(true);
          setVideoSrc(newVideoSrc);

          // Update the video element's src if it's mounted
          if (videoRef.current) {
            videoRef.current.src = newVideoSrc;
            videoRef.current.play().catch(error => {
              // Handle exceptions like play() returning a promise
              console.error('Video play error: ', error);
            });
          }
        };

        video.addEventListener('canplaythrough', handleCanPlayThrough);

        video.onerror = () => {
          console.error(`Error preloading video ${newVideoSrc}`);
        };

        // Clean up event listener
        return () => {
          video.removeEventListener('canplaythrough', handleCanPlayThrough);
        };
      }
    } else {
      // No aiVideoLayer, clear the video
      if (videoSrc !== '') {
        setVideoSrc('');
        setIsVideoReady(false);

        if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.src = '';
        }
      }
    }
  }, [aiVideoLayer, videoSrc]);

  // Seek to currentLayerSeek when video is ready or when currentLayerSeek changes
  useEffect(() => {
    if (videoRef.current && !isNaN(currentLayerSeek)) {
      const video = videoRef.current;

      const seekToTime = () => {
        video.currentTime = currentLayerSeek;
      };

      if (isVideoReady) {
        seekToTime();
      } else {
        const handleLoadedData = () => {
          seekToTime();
        };

        video.addEventListener('loadeddata', handleLoadedData, { once: true });

        return () => {
          video.removeEventListener('loadeddata', handleLoadedData);
        };
      }
    }
  }, [currentLayerSeek, isVideoReady]);

  return (
    <div
      style={{
        position: 'relative',
        width: `${canvasDimensions.width}px`,
        height: `${canvasDimensions.height}px`,
        overflow: 'hidden',
      }}
    >
      <div
        className="absolute top-0 left-0"
        style={{
          width: '100%',
          height: '100%',
          pointerEvents: 'none', // Allow clicks to pass through if needed
          marginLeft: '1px',
          marginTop: '1px',
        }}
      >
        <video
          ref={videoRef}
          src={videoSrc}
          muted
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover', // Ensures the video fills the container
          }}
        />
      </div>
      {aiVideoLayer && (
        <div
          className="absolute top-0 left-0 z-10"
          style={{ pointerEvents: 'auto' }} // Ensure the overlay can be interacted with
        >
          <div className="bg-gray-900 border border-neutral-200 rounded-lg w-32 m-auto relative">
            Video Layer
            <FaTimes
              className="inline-flex ml-2 mt-[-2px] cursor-pointer hover:text-neutral-400"
              onClick={removeVideoLayer}
            />
          </div>
        </div>
      )}
      {isVideoReady === false && aiVideoLayer && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-white">Loading video...</div>
        </div>
      )}
    </div>
  );
}