import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { FaPlay, FaPause, FaDownload } from 'react-icons/fa';
import { getHeaders } from '../../../utils/web'; // Adjust the path as needed
const API_SERVER = process.env.REACT_APP_PROCESSOR_API;

export default function SceneLibraryHome(props) {
  const { hideSelectButton, onSelectVideo } = props;
  const [libraryData, setLibraryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 12; // Adjust as needed

  const [playingVideoId, setPlayingVideoId] = useState(null);
  const videoRefs = useRef({});

  // New state to track trim checkbox for each video
  const [trimScenes, setTrimScenes] = useState({});

  useEffect(() => {
    fetchLibraryData();
  }, [currentPage, searchTerm]);

  const fetchLibraryData = async () => {
    const headers = getHeaders();
    try {
      const response = await axios.get(
        `${API_SERVER}/ai_video/user_video_library?page=${currentPage}&limit=${itemsPerPage}&search=${encodeURIComponent(
          searchTerm
        )}`,
        headers
      );
      const libraryData = response.data.items;
      setLibraryData(libraryData);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching video library data:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePlayPause = (videoId) => {
    const videoElement = videoRefs.current[videoId];
    if (!videoElement) return;

    if (playingVideoId === videoId) {
      videoElement.pause();
      setPlayingVideoId(null);
    } else {
      if (playingVideoId && videoRefs.current[playingVideoId]) {
        videoRefs.current[playingVideoId].pause();
      }

      videoElement.play();
      setPlayingVideoId(videoId);

      videoElement.onended = () => {
        setPlayingVideoId(null);
      };
    }
  };

  const handleDownload = (item) => {
    const link = document.createElement('a');
    link.href = `${API_SERVER}/${item.url}`;
    link.download = `${item.description || 'Video'}.mp4`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Updated handleSelect to send payload with video and trimScene
  const handleSelect = (item) => {
    if (onSelectVideo) {
      const payload = {
        video: item,
        trimScene: trimScenes[item._id] || false,
      };
      onSelectVideo(payload);
    }
  };

  // Handler to toggle trimScene for a video
  const handleTrimChange = (videoId) => {
    setTrimScenes((prevTrimScenes) => ({
      ...prevTrimScenes,
      [videoId]: !prevTrimScenes[videoId],
    }));
  };

  return (
    <div className="bg-gray-900 relative text-white library-home-container mb-4 pb-4">
      {/* Top Toolbar */}
      <div className="flex items-center justify-between p-4 border-b border-gray-700">
        <div className="flex items-center">
          {/* Pagination Controls */}
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="px-3 py-1 bg-gray-700 rounded-l disabled:opacity-50"
          >
            Prev
          </button>
          <span className="px-4 py-1 bg-gray-800">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-3 py-1 bg-gray-700 rounded-r disabled:opacity-50"
          >
            Next
          </button>
          {/* Search Bar */}
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            className="ml-4 px-3 py-1 bg-gray-800 border border-gray-600 rounded focus:outline-none"
          />
        </div>
      </div>

      {/* Grid Display */}
      <div className="p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 h-auto overflow-auto">
        {libraryData.map((item) => (
          <div key={item._id} className="bg-gray-800 p-4 rounded">
            {/* Video Thumbnail and Play/Pause Button */}
            <div className="relative">
              <video
                ref={(el) => {
                  videoRefs.current[item._id] = el;
                }}
                src={`${API_SERVER}/${item.url}`}
                className="w-full h-48 object-cover rounded cursor-pointer"
                controls={false}
                preload="metadata"
                onClick={() => handlePlayPause(item._id)}
              />
              <button
                className="absolute bottom-2 right-2 bg-neutral-800 hover:bg-neutral-900 p-2 rounded-full"
                onClick={() => handlePlayPause(item._id)}
              >
                {playingVideoId === item._id ? (
                  <FaPause className="text-white" />
                ) : (
                  <FaPlay className="text-white" />
                )}
              </button>
            </div>
            {/* Description */}
            <h2 className="mt-2 text-lg font-semibold">
              {item.description || 'No Description'}
            </h2>
            {/* Model and Prompt */}
            <p className="text-sm text-gray-400">
              Model: {item.model || 'Unknown'}
            </p>
            <p className="text-sm text-gray-400">
              Prompt: {item.prompt || 'No Prompt'}
            </p>
            {/* Download and Select Buttons */}
            <div className="mt-2 flex justify-between items-center">
              <button
                className="bg-neutral-800 hover:bg-neutral-700 px-3 py-2 rounded"
                onClick={() => handleDownload(item)}
              >
                <FaDownload className="text-white" />
              </button>
              {!hideSelectButton && (
                <div className="flex items-center">
                  <label className="text-xs mr-2 flex items-center">
                    <input
                      type="checkbox"
                      checked={trimScenes[item._id] || false}
                      onChange={() => handleTrimChange(item._id)}
                      className="mr-1"
                    />
                    Trim
                  </label>
                  <button
                    className="bg-gradient-to-r from-blue-900 via-blue-800 to-blue-900 hover:bg-purple-700 px-3 py-2 rounded"
                    onClick={() => handleSelect(item)}
                  >
                    Select
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
